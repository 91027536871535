<!--
 * @Author: your name
 * @Date: 2020-04-24 18:07:30
 * @LastEditTime: 2020-04-27 10:56:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\tasking\dialog\historyDialog.vue
 -->
<template>
  <div class="dialog-container">
    <el-dialog
      title="历史记录"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <div class="history-wrapper">
        <el-row>
          <el-table
            :data="tableData"
            :height="tableHeight"
            style="width: 100%"
            border>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="55">
            </el-table-column>
            <el-table-column
              prop="title"
              align="center"
              width="105"
              label="申请标题">
            </el-table-column>
            <el-table-column
              prop="taskName"
              min-width="168"
              align="center"
              label="任务名称">
            </el-table-column>
            <el-table-column
              prop="auditor"
              align="center"
              min-width="168"
              label="审批人">
            </el-table-column>
            <el-table-column
              prop="result"
              align="center"
              min-width="115"
              label="审批结果">
            </el-table-column>
            <el-table-column
              prop="duration"
              width="210"
              align="center"
              label="耗时">
            </el-table-column>
            <el-table-column
              prop="startTime"
              width="210"
              align="center"
              label="指派时间">
            </el-table-column>
            <el-table-column
              prop="endTime"
              width="210"
              align="center"
              label="审批时间">
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="padding:50px 0">
          <img :src="previewImage" alt="" srcset="">
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixinDialog from '@/mixins/dialogMixin'
import mixin from '@/mixins/index'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin, mixinDialog],
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      previewImage: "",
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      tableData: [],
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /* eslint-disable */
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    searchData(data) {
      if (!data) {
        return false;
      }
      // 在这里获取回显的数据
      this.$http.get(`${apis.taskFlow}`, {
        params: {
          procInstId: data.procInstId,
        },
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.onPreviewImages(data);
        }
      })
    },
    /**
     * 获取历史流程图片
     */
    onPreviewImages(record) {
      this.$http.get(`${apis.process}/highlightImg/${record.procInstId}`, {
        responseType: 'blob',
      }).then((res) => {
        const raw = res.data
        this.previewImage = URL.createObjectURL(raw)
      }, (e) => {
        console.log(e)
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
