/*
 * @Author: your name
 * @Date: 2020-04-24 17:02:11
 * @LastEditTime: 2020-04-24 18:01:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\tasking\dialog\addDialogClass.js
 */
import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.leaveType = ''
      this.title = ''
      this.description = ''
      this.startDate = ''
      this.duration = ''
      this.comment = ''
    } else if (type === 'rule') {
      this.leaveType = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_leaveType,
          trigger: 'blur',
        },
      ]
      this.description = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_description,
          trigger: 'blur',
        },
      ]
      this.startDate = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_startDate,
          trigger: 'blur',
        },
      ]
      this.duration = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_duration,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
